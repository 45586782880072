import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  Box, Container, Typography, Button, Dialog, AppBar, Toolbar, IconButton, Slide, Grid
} from '@mui/material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CloseIcon from '@mui/icons-material/Close';
import JSZip from 'jszip';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const QualtyResultView = () => {
  const [assetPathData, setAssetPathData] = useState({});
  const [open, setOpen] = React.useState(false);


  const dowlaodReportAssetAPI = async (assetPath) => {
    const baseUrl = process.env.REACT_APP_APPREG_API_BASE_URL;
    const fetchUrl = baseUrl + "QualityResultAssetContentFetchService";
    const response = await fetch(fetchUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ blobPath: assetPath }),
    });
    if (response && response.status === 200) {
      return Promise.resolve(response.text());
    } else {
      return "Download Failed";
    }
  };

  const dowlaodReportAssetUI = async (assetPath) => {
    const baseUrl = process.env.REACT_APP_APPREG_API_BASE_URL;
    const fetchUrl = baseUrl + "QualityResultAssetContentFetchService";
    const response = await fetch(fetchUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/zip',
      },
      body: JSON.stringify({ blobPath: assetPath }),
    });
    if (response && response.status === 200) {
      return Promise.resolve(response.blob());
    } else {
      return "Download Failed";
    }
  };


  const handleReportViewerModalOpen = (paramFolderPath, paramAssetType) => {
    if (paramAssetType === "UI") {
      dowlaodReportAssetUI(paramFolderPath).then((resultZipContent) => {
        JSZip.loadAsync(resultZipContent).then((zip) => {
          zip.forEach((relativePath, zipEntry) => {            
          });
          return zip.file("index.html").async("string");
        }).then(function success(text) {          
          var externalWindowPopup = window.open("about:blank", "", "_blank");
          externalWindowPopup.document.write(text);
        }, function error(e) {
          console.log(e);
        });
      }
      )
    } else if (paramAssetType === "API") {
      dowlaodReportAssetAPI(paramFolderPath).then((resultHtmlContent) => {        
        var externalWindowPopup = window.open("about:blank", "", "_blank");
        externalWindowPopup.document.write(resultHtmlContent);
      }, function error(e) {
        console.log(e);
      }
      )
    }
    setOpen(false);
  };


  const handleReportViewerModalClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    async function fetchData() {
      const baseUrl = process.env.REACT_APP_APPREG_API_BASE_URL;
      const fetchUrl = baseUrl + "QualityResultAssetPathsFetchService";
      const response = await fetch(fetchUrl, {
        // headers: {  
        //     'Authorization': `Bearer ${accessToken}`, 
        // }   
      });
      if (response && response.status === 200) {
        const responseData = await response.json();
        setAssetPathData(responseData)
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <Box display="flex" flexDirection="column" minHeight="100vh" >
        <Box bgcolor="#EEF4FF" pt={6} pb={3} px={10} sx={{ margin: 0 }}>
          <Container maxWidth={false}>
            <Typography fontWeight={700} fontSize="20px" gutterBottom sx={{ color: '#00184D' }} >
              Quality Test Results & Reports
            </Typography>
          </Container>
        </Box>
        <Box px={10} pb={10} sx={{ margin: 0 }}>
          <Container maxWidth={false}>
            {Object.keys(assetPathData).map((item, index) => (
              <Box p={2} key={index}>
                <Typography mt="32px" mb="0px" fontWeight={700} fontSize="20px" gutterBottom sx={{ color: '#00184D' }}>  
                  {assetPathData[item].qualityResultAssetGroupName.split("|")[1]}
                </Typography>             
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>                 
                  {assetPathData[item].qualityResultAssets.map((item, idx) => (
                    <Grid item xs={2} sm={4} md={4} key={index}>
                      <Button variant="outlined" onClick={() => handleReportViewerModalOpen(item.assetBlobContentPath, item.assetType)} startIcon={<SummarizeIcon />}>
                        {item.assetDisplayName}
                      </Button>
                    </Grid>
                  ))}
                </Grid>                
              </Box>
            ))}
          </Container>
        </Box>
      </Box>

      {/* Quality Result Viewer Modal Window */}
      <Dialog fullScreen open={open} onClose={handleReportViewerModalClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleReportViewerModalClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div"></Typography>
            <Button autoFocus color="inherit" onClick={handleReportViewerModalClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
      </Dialog>
    </>
  );
};

export default QualtyResultView;  